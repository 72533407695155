<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <!-- logo -->
          <h2 class="brand-text text-primary ml-1">BioKeep</h2>
        </b-link>

        <b-card-text class="text-center mt-2">
          <b-link class="btn btn-lg btn-relief-primary btn-lg" to="/en">
            Continue With <u>English</u>
          </b-link>
          <br /><br />
          <b-link class="btn btn-lg btn-relief-success btn-lg" to="/ar">
            استمرار باللغة <u>العربية</u>
          </b-link>
        </b-card-text>
      </b-card>
      <!-- /Forgot Password v1 -->
    </div>
  </div>
</template>

<script>
import { BLink, BCard, BCardText } from "bootstrap-vue";

export default {
  components: {
    BLink,
    BCard,
    BCardText,
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
